:root {
  --bg-transparent-color: rgba(255, 255, 255, 0.85);
}

.mynav {
  background: var(--bg-transparent-color);
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.5);
  height: 60px;
  transition: all 0.5s ease;

  display: flex;
  align-items: center;
  justify-content: flex-start;
}
