:root {
  --bg-primary: #f4f4f4;
  --bg-secondary: #f4f4f4;
  --text-primary: #000000;
  --text-secondary: #444444;
  --text-invert: #ffffff;
  --shadow-primary: 12px 12px 24px 8px rgba(0, 0, 0, 0.16);
  --shadow-secondary: -12px -12px 24px 8px rgba(255, 255, 255, 0.8);
  --shadow-primary: 4px 4px 8px 2px rgba(0, 0, 0, 0.08);
  --shadow-secondary: -4px -4px 8px 2px rgba(255, 255, 255, 0.8);
  --nav-shadow-primary: 4px 4px 8px 2px rgba(0, 0, 0, 0.08);
  --nav-shadow-secondary: -4px -4px 8px 2px rgba(255, 255, 255, 0.8);
  --button-primary: #282828;
  --button-danger: #da0000;
  --button-secondary: #888888;
  --button-secondary-bg: #dddddd;
  --line: #dddddd;
}

.container {
  max-width: 1920px;
  margin: 0 auto;
}

.manage-console {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 70vh;
}

.admin-tab {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.tab {
  width: 33%;
}

.search-element {
  display: flex;
  height: 40px;
  justify-content: space-between;
}

.search-type {
  width: 100%;
}

.search-bar {
  border-radius: 4px;
  width: 60%;
  height: 40px;
  display: flex;
  align-items: center;
  background: white;
}

.search-button {
  width: 20%;
  border-radius: 5px;
}

.card-con {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: auto;
}

.card-text {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

hr {
  color: #000000;
  background: #000000;
  border-color: #000000;
  width: 100%;
}

.blocker {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: " ";
  background: rgba(0, 0, 0, 0.5);
}

.box {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  line-height: 50px;
  text-align: center;
  margin: auto;
}

.background {
  /*background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);*/
  background: var(--bg-primary);
}

.home-block {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.page-block {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
}

.home-card {
  /*background: linear-gradient(0deg, #ffffff, #ffffff);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);*/
  background: var(--bg-primary);
  box-shadow: var(--shadow-primary), var(--shadow-secondary);
  border-radius: 32px;
  padding: 32px;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100vh;
  /* width: 100%; */
  max-width: 350px;
}

.tab-card {
  /*background: linear-gradient(0deg, #ffffff, #ffffff);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);*/
  background: var(--bg-primary);
  box-shadow: var(--shadow-primary), var(--shadow-secondary);
  border-radius: 16px;
  margin-top: 8px;
  margin-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  width: 800px;
  /*max-width: 800px;*/
}

.user-manager-card {
  /*background: linear-gradient(0deg, #ffffff, #ffffff);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);*/
  background: #dddddd;
  border-radius: 16px;
  margin-top: 8px;
  margin-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  height: auto;
  color: black;
  display: flex;
  /* flex: 1; */
  padding-top: 2px;
  padding-bottom: 4px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /*max-width: 800px;*/
}

.device-manager-card {
  /*background: linear-gradient(0deg, #ffffff, #ffffff);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);*/
  background: #dddddd;
  border-radius: 16px;
  margin-top: 8px;
  margin-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  width: 90%;
  height: auto;
  color: black;
  display: flex;
  /* flex: 1; */
  padding-top: 2px;
  padding-bottom: 4px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /*max-width: 800px;*/
}

.console {
  /*background: linear-gradient(0deg, #ffffff, #ffffff);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);*/
  background: var(--bg-primary);
  /* box-shadow: var(--shadow-primary), var(--shadow-secondary); */
  border-radius: 16px;
  padding: 16px;
  margin-top: 8px;
  margin-bottom: 16px;
  width: 85%;
  max-width: 770px;
}

.data-card {
  /*background: linear-gradient(0deg, #ffffff, #ffffff);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);*/
  background: var(--bg-primary);
  /* box-shadow: var(--shadow-primary), var(--shadow-secondary); */
  border-radius: 16px;
  padding: 16px;
  margin-top: 8px;
  margin-bottom: 16px;
  width: 25%;
  /*max-width: 800px;*/
}

.data-con {
  width: 95%;
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: center;
}

.serial-name {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  text-align: left;
}

.machine-type {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  text-align: left;
}

.button {
  width: 100%;
  height: 48px;
  margin-top: 10px;
  background-color: var(--button-primary);
  /* background-color: #3f51b5; */
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-danger {
  width: 100%;
  height: 48px;
  margin-top: 10px;
  background-color: var(--button-danger);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button:hover {
  opacity: 0.85;
  transition: all ease-in 0.25s;
}

.button-border {
  width: 100%;
  height: 48px;
  margin-top: 10px;
  border: solid 1px var(--button-secondary);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-circle {
  width: 60px;
  height: 60px;
  box-shadow: var(--shadow-primary), var(--shadow-secondary);
  border-radius: 60px;
}

.button-circle:hover {
  /*box-shadow: inset 4px 4px 16px rgba(0, 0, 0, 0.08);*/
  transition: all ease-in 0.25s;
}

.button-text {
  color: var(--text-primary);
  font-weight: 600;
}

.button-text-white {
  color: var(--text-invert);
  font-weight: 600;
}

.textfield {
  width: 100%;
  margin-bottom: 20px;
  height: 48px;
}

.text-title {
  font-size: 28px;
  font-weight: 600;
  color: var(--text-primary);
  margin-top: 10px;
  margin-bottom: 10px;
}

.text-subtitle {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-primary);
  margin-top: 10px;
  margin-bottom: 10px;
}

.text-plain {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-primary);
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}
.text-option {
  font-size: 12px;
  font-weight: 500;
  color: var(--text-secondary);
  text-align: center;
}

.space-text {
  margin-top: 32px;
  margin-bottom: 32px;
}

.picker-box {
  width: 100%;
  height: 104px;
  border: 2px dashed #cccccc;
  border-radius: 8px;
  box-sizing: border-box;
  margin-top: 8px;
  margin-bottom: 8px;
}

.status-box {
  width: 40px;
  height: 40px;
  /*background: linear-gradient(135deg, #e1edf8 0%, #eef1f3 100%);
  box-shadow: inset -12px -10px 12px rgba(255, 255, 255, 0.76),
    inset 0px 9px 18px #c9d3e2, inset 0px 2px 22px #c8d4e2;
  */
  background: #ffffff;
  box-shadow: inset 8px 8px 8px rgba(0, 0, 0, 0.12);
  border-radius: 40px;
  display: flex;
  /*margin: auto;*/
}

.status-block {
  background: #fafafa;
  /*width: 100%;*/
  height: 64px;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.text-status-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--text-primary);
  text-align: center;
  /*margin: auto;*/
}

.text-status-status {
  font-size: 10px;
  font-weight: 500;
  color: var(--text-secondary);
  text-align: center;
  /*margin: auto;*/
}

.status-show-block {
  background: #fafafa;
  /*width: 100%;*/
  height: 112px;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}

.nav-bar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
  /*margin-bottom: 16px;*/
  /*border-bottom: solid 1px var(--line);*/
}

.nav-logo {
  /*width: 50px;*/
  height: 60px;
}

.nav-logo-block {
  /*background: var(--bg-primary);
  box-shadow: var(--nav-shadow-primary), var(--nav-shadow-secondary);*/
  border-radius: 16px;
  padding: 4px;
  width: fit-content;
}

.nav-button-block {
  background: var(--bg-primary);
  box-shadow: var(--nav-shadow-primary), var(--nav-shadow-secondary);
  border-radius: 16px;
  padding: 4px;
  width: fit-content;
}

.nav-button {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-primary);
  /*margin-top: 10px;
  margin-bottom: 10px;*/
  width: 60px;
  height: 60px;
  /*padding-left: 8px;
  padding-right: 8px;*/
}

.nav-header {
  font-size: 14px;
  font-weight: 800;
  color: var(--text-secondary);
  margin: auto;
}
.popup-card {
  /*background: linear-gradient(0deg, #ffffff, #ffffff);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);*/
  background: var(--bg-primary);
  /* box-shadow: var(--shadow-primary), var(--shadow-secondary); */
  border-radius: 32px;
  padding: 32px;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
  max-width: 480px;
}
.device-card {
  /*background: linear-gradient(0deg, #ffffff, #ffffff);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);*/
  /* background: var(--bg-primary); */
  /* box-shadow: var(--shadow-primary), var(--shadow-secondary);
  border-radius: 16px; */
  padding: 16px;
  margin-top: 8px;
  margin-bottom: 16px;
  width: 100%;
  /*max-width: 800px;*/
}
.main.chart-wrapper {
  width: 96%;
  height: 400px;
}

.mobile-search-type {
  display: none;
}

.mobile-search-button {
  display: none;
}

/* ----------- Mobile ----------- */
@media only screen and (min-device-width: 280px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .mobile-search-type {
    display: flex;
    justify-content: center;
    height: 40px;
    align-items: center;
    width: 10%;
  }

  .mobile-menu {
    display: flex;
    flex-direction: column;
    height: auto;
    position: absolute;
    transition: 0.2s ease;
    top: 20vh;
    opacity: 0;
  }

  .mobile-search-button {
    display: flex;
    justify-content: center;
    height: 40px;
    align-items: center;
    width: 10%;
  }

  .user-manager-card {
    background: #dddddd;
    border-radius: 16px;
    margin-top: 8px;
    margin-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
    width: 90%;
    height: auto;
    color: black;
    display: flex;
    padding-top: 2px;
    padding-bottom: 4px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .data-card {
    background: var(--bg-primary);
    border-radius: 16px;
    padding: 16px;
    margin-top: 8px;
    margin-bottom: 16px;
    width: 80%;
  }

  .console {
    background: var(--bg-primary);
    border-radius: 16px;
    padding: 16px;
    margin-top: 8px;
    margin-bottom: 16px;
    width: 80%;
  }

  .search-type {
    display: none;
  }

  .search-bar {
    border-radius: 4px;
    width: 75%;
    height: 40px;
    display: flex;
    align-items: center;
    background: white;
  }

  .search-button {
    display: none;
  }

  .admin-tab {
    overflow-x: hidden;
  }
}

/* ----------- Tablet ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .mobile-search-type {
    display: flex;
    justify-content: center;
    height: 40px;
    align-items: center;
    width: 10%;
  }

  .mobile-menu {
    display: flex;
    flex-direction: column;
    height: auto;
    position: absolute;
    transition: 0.2s ease;
    top: 20vh;
    opacity: 0;
  }

  .mobile-search-button {
    display: flex;
    justify-content: center;
    height: 40px;
    align-items: center;
    width: 10%;
  }

  .user-manager-card {
    background: #dddddd;
    border-radius: 16px;
    margin-top: 8px;
    margin-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
    width: 90%;
    height: auto;
    color: black;
    display: flex;
    padding-top: 2px;
    padding-bottom: 4px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .data-card {
    background: var(--bg-primary);
    border-radius: 16px;
    padding: 16px;
    margin-top: 8px;
    margin-bottom: 16px;
    width: 40%;
  }

  .console {
    background: var(--bg-primary);
    border-radius: 16px;
    padding: 16px;
    margin-top: 8px;
    margin-bottom: 16px;
    width: 80%;
  }

  .search-type {
    display: none;
  }

  .search-bar {
    border-radius: 4px;
    width: 80%;
    height: 40px;
    display: flex;
    align-items: center;
    background: white;
  }

  .search-button {
    display: none;
  }

  .admin-tab {
    overflow-x: scroll;
  }
}
